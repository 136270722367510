.upload-file-delete-btn {
  cursor: pointer;
}

.upload-file-delete-btn:hover {
  color: grey;
}

.upload-file-delete-btn:active {
  transform: translateY(1px);
}
